<template>
  <div></div>
</template>

<script>
export default {
  name: "new-index",
  layout:{
    name:'new-admin',
  },
}
</script>

<style scoped>

</style>